@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';

.customCSS .e-calendar .e-content .e-selected span.e-day, /* csslint allow: adjoining-classes */
.customCSS .e-calendar .e-content .e-selected span.e-day:hover, /* csslint allow: adjoining-classes */
.customCSS .e-calendar .e-content .e-today.e-selected:hover span.e-day, /* csslint allow: adjoining-classes */
.customCSS .e-calendar .e-content .e-today.e-selected span.e-day, /* csslint allow: adjoining-classes */
.customCSS .e-calendar .e-content .e-selected:hover span.e-day,
.customCSS .e-calendar .e-content .e-start-date span.e-day {
  background-color: #3cb8a4 !important;
}
.customCSS .e-calendar .e-content .e-today span.e-day, /* csslint allow: adjoining-classes */ 
.customCSS .e-calendar .e-content .e-focused-date.e-today span.e-day {
  /* csslint allow: adjoining-classes */
  border: 1px solid #3cb8a4 !important;
  color: #3cb8a4 !important;
}
.customCSS
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover.e-today
  span.e-day,
.customCSS
  .e-bigger.e-small
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover.e-today
  span.e-day {
  /* csslint allow: adjoining-classes */
  border: 1px solid blue !important;
  color: blue !important;
}
.customCSS .e-calendar .e-content .e-weekend span {
  /* csslint allow: adjoining-classes */
  /* color: #3cb8a4; */
}
.customCSS.e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  /* csslint allow: adjoining-classes */
  /* csslint allow: adjoining-classes */
  color: #3cb8a4;
}

.customCSS .e-cell .e-start-date .e-selected .e-range-hover {
  background-color: blue;
}

.table th {
  text-transform: uppercase;
  font-weight: bold;
}

.table tbody tr {
  cursor: pointer;
}

.table tbody .no-pointer {
  cursor: auto;
}

.container {
  margin: 16px;
  padding: 16px;
}

.table-container {
  overflow-x: auto;
}

.grow {
  flex-grow: 1;
}

.container-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inactive {
  color: red;
}

.container fieldset {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container .block {
  display: block;
}

.help-text {
  font-size: 0.75rem;
}
.help-text li {
  margin-bottom: 0.5em;
}

.form {
  margin-left: auto;
  margin-right: auto;
}

.snack-text {
  display: flex;
  align-items: center;
}

.left-icon {
  margin-right: 8px;
}

.right-icon {
  margin-left: 8px;
}

.container .button {
  margin: 8px;
}

.stickyHeader th {
  position: sticky;
  top: 0;
  background: #fff;
}

.tooltip {
  padding-left: 0;
}

.tooltip li {
  list-style: none;
}

.logo {
  width: 100px;
  margin-left: 20px;
}
